// IMPORTS
@import (once) "/node_modules/swiper/swiper.less";
@import (once) "/node_modules/swiper/modules/navigation.less";
@import (once) "/node_modules/swiper/modules/pagination.less";
@import (once) "/node_modules/swiper/modules/autoplay.less";

@import (once) "settings.less";

// GENERAL
body {
    overflow-x: hidden;
    background-color: white;
}

.goshop-modal-content {
    display: none;
}

#container {
    min-height: 100vh;
    min-height: -webkit-fill-available;

    // IMPORTANT
    display: flex;
    flex-direction: column;

    .inner-wrapper > *:not(.inner) {
        width: 100%;
    }

    #footer {
        margin-top: auto;
    }
}

// SHOP-HEADER
.header-sentinel {
    position: absolute;
    top: 0;
    left: 0;
}

.privacy-sentinel {
    height: 0;
    clear: both;
}

.top-bar {
    font-size: 14px;
    color: @footer-gray;
    padding: 14px 10px;
    margin: 0 auto;
    text-align: center;
    position: relative;
    gap: 4px;
    white-space: nowrap;

    &:after {
        .full-bg();
        background-color: @header-color;
    }
}


// HEADER CONTAINER
.shop-header-container {
    .grid();
    grid-template-columns: minmax(0, 412px) minmax(0, 1fr);
    grid-template-rows: auto auto;
    gap: 0 169px;
    padding-block: 20px 24px;
}

.logo {
    display: flex;
    align-items: stretch;
    transform-origin: left top;
    transition: transform .2s ease-in-out;
    grid-row: 1 / 3;
    align-self: end;

    img {
        display: block;
        height: 96px;
        width: auto;
    }
}

.header-bar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 24px;
    color: @font-color;
    padding-bottom: 20px;
    margin-bottom: 24px;
    position: relative;
    border-bottom: 1px solid @border-color;
    font-size: 14px;
    white-space: nowrap;

    .header-bar-left,
    .header-bar-right {
        display: flex;
        align-items: center;
        gap: 24px;

        a,
        .header-location-trigger {
            color: @font-color;
            display: flex;
            align-items: center;
            gap: 10px;

            &:before {
                .small-icon();
            }
        }
    }

    .phone:before {
        content: "\e91b";
    }

    .email:before {
        content: "\e91a";
    }

    .header-bar-right {

        a:hover {
            color: @header-color;
        }

        .reg-button:before {
            content: "\e91c";
        }

        .log-button {
            white-space: nowrap;
        }

        .log-button:before {
            content: "\e918";
        }
    }
}

.header-location-trigger {
    user-select: none;
    position: relative;

    &:before {
        content: "\e919";
    }

    &:after {
        .small-icon();
        font-size: 16px;
        content: "\e914";
        margin-left: -2px;
    }

    &:hover {
        cursor: pointer;
        color: @header-color !important;
    }

    .header-location-open {
        position: absolute;
        transform: translate(0, -10px);
        background: @sec-color;
        color: @footer-gray;
        cursor: initial;
        gap: 4px;
        filter: drop-shadow(0 3px 8px rgba(0, 0, 0, 0.2));
        opacity: 0;
        visibility: hidden;
        transition: all 0.2s ease;
        border-radius: var(--borderRadius);
        top: calc(100% + 10px);
        right: 0;
        display: flex;
        flex-direction: column;
        font-size: 16px;
        padding: 4px;
        max-width: 488px;
        white-space: initial;
        z-index: 102;

        .location-text {
            user-select: initial;
            padding: 12px;
            display: flex;
            flex-direction: column;

            > b {
                padding-bottom: 4px;
            }

            > span {
                font-weight: initial;
                line-height: 1.2;
                font-size: 14px;
            }
        }

        .map-wrapper {
            width: 480px;
            aspect-ratio: ~"480/320";

            iframe {
                .img-contain();
                display: none;
                border-radius: 4px;
            }
        }
    }

    &.open {

        &:after {
            transform: rotate(180deg);
        }

        .header-location-open {
            opacity: 1;
            visibility: visible;
            transform: translate(0, 0);
            display: flex;

            iframe {
                display: block;
            }
        }
    }
}

.header-bottom {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

// HEADER ICONS
.header-tools-wrapper {
    display: flex;
    gap: 32px;
}

@media (min-width: 1280px) {
    .tree-trigger {
        display: none;
    }

    .tree-canvas {
        display: none;
    }

    #cart-box,
    #wishlist-box {
        position: relative;
        transition: transform .2s ease-in-out;
        padding-left: 44px;

        &:before {
            .small-icon();
            position: absolute;
            left: 0;
            top: 3px;
            font-size: 32px;
            line-height: 32px;
            height: 32px;
            color: @header-color;
        }

        span,
        b {
            display: block;
            color: @header-color;
            font-size: 14px;
        }

        em {
            display: none;
        }

        span {
            color: @font-color;
        }

        b {
            margin-top: -5px;
            color: @header-color;
        }

        &:hover {

            span {
                color: @header-color;
            }
        }
    }

    #wishlist-box:after,
    #cart-box i {
        content: attr(data-wishlist-count);
        position: absolute;
        top: 8px;
        left: 27px;
        background-color: @main-color;
        width: 8px;
        aspect-ratio: 1;
        font-size: 0px;
        color: transparent;
        border-radius: 50px;
    }

    #cart-box {

        &:before {
            content: "\e915";
        }
    }

    #wishlist-box {

        &:before {
            content: "\e916";
        }

        &[data-wishlist-count="0"]:after {
            display: none;
        }
    }
}

// SEARCH ENGINE
.search-engine-trigger,
.search-engine-close {
    display: none;
}

@media (min-width: 1280px) {
    #search-engine {
        position: relative;
        width: 411px;
        float: right;
        z-index: 101;
        transition: transform .2s ease-in-out;

        &.show,
        &.show-last-phrases {

            #suggestions {
                opacity: 1;
                visibility: visible;
                transform: translateY(0);
                transition: all .2s ease-in-out;
            }
        }

        &:not(.show-last-phrases) {

            .suggestion-last-phrases {
                display: none;
            }

            &:has(.suggestion-result-products:empty) {

                .no-products-info {
                    display: block !important;
                }
            }
        }

        input[type="text"] {
            width: 100%;
            height: 48px;
            padding: 0 52px 0 20px;
            background-color: white;
            color: @header-color;
            font-size: 16px;
            font-family: @main-font-family;
            border: 1px solid @border-sec-color;
            position: relative;

            &::-webkit-input-placeholder {
                color: @font-color;
            }

            &::-moz-placeholder {
                color: @font-color;
            }

            &:-ms-input-placeholder {
                color: @font-color;
            }

            &:-moz-placeholder {
                color: @font-color;
            }
        }

        button {
            position: absolute;
            top: 0;
            right: 0;
            width: 52px;
            height: 48px;
            color: @header-color;
            display: flex;
            justify-content: center;
            align-items: center;

            &:after {
                .small-icon();
                content: "\e917";
            }

            &:hover {
                color: @main-color;
            }
        }

        // corner decals
        &:after,
        &:before,
        form:after,
        form:before {
            content: '';
            width: 6px;
            aspect-ratio: 1;
            position: absolute;
            border-color: @header-color;
            border-style: solid;
            z-index: 1;
        }
        &:after {
            top: 4px;
            left: 4px;
            border-width: 1px 0 0 1px;
        }
        &:before {
            bottom: 4px;
            left: 4px;
            border-width: 0 0 1px 1px;
        }
        form:after {
            top: 4px;
            right: 4px;
            border-width: 1px 1px 0 0;
        }
        form:before {
            bottom: 4px;
            right: 4px;
            border-width: 0 1px 1px 0;
        }
    }
}

// SUGGESTIONS
#suggestions {
    position: absolute;
    top: ~"calc(100% + 10px)";
    left: 0;
    background-color: white;
    width: 720px;
    padding: 22px;
    .shadow();
    opacity: 0;
    visibility: hidden;
    display: flex;
    flex-wrap: wrap;
    transform: translateY(-10px);

    .no-products-info {
        display: none;
        font-size: 18px;
        font-weight: bold;
        color: @header-color;
        width: 100%;
        margin-bottom: 10px;
    }

    [data-label] {
        margin-bottom: 10px;

        &:empty {
            display: none;
        }
    }

    &.loading {

        > *:not(.suggestions-loading) {
            display: none;
        }

        .suggestions-loading {
            display: flex;
        }

        .no-products-info {
            display: none !important;
        }
    }
}

.suggestions-loading {
    height: 200px;
    justify-content: center;
    align-items: center;
    width: 100%;
    display: none;

    span {
        border-radius: 50%;
        border: 4px solid @border-color;
        border-top-color: @header-color;
        animation: spin .5s infinite linear;
        transform: translateZ(0) rotate(0deg);
        transform-origin: center;
        -webkit-transform-style: preserve-3d;
        width: 100px;
        height: 100px;
    }
}

@media (min-width: 1280px) {
    .suggestion-column-wrapper {
        display: none;
        width: 100%;

        &:has([data-label]:not(:empty)) {
            display: flex;

            & + .suggestion-last-phrases {
                display: none;
            }
        }
    }
}

.suggestion-left-column {
    width: 33%;
    flex-shrink: 0;
    display: none;

    &:has(:not(:empty)) {
        display: block;
    }
}

.suggestion-result-categories,
.suggestion-result-producers,
.suggestion-result-products,
.suggestion-last-phrases {

    &:before {
        content: attr(data-label);
        display: block;
        color: @header-color;
        font-weight: bold;
        font-size: 18px;
    }
}

.suggestion-result-categories,
.suggestion-result-producers {
    padding-right: 30px;

    a {
        display: inline-block;
        padding: 2px 0;
        color: @font-color;

        &:hover {
            text-decoration: underline;
        }
    }
}

.suggestion-last-phrases {
    width: 100%;
    margin-bottom: 10px;

    span {
        display: flex;
        align-items: center;
        cursor: pointer;
        padding: 5px 0;

        &:before {
            content: '\e802';
            font-family: icomoon;
            color: @header-color;
            float: left;
            width: 35px;
            font-size: 18px;
            flex-shrink: 0;
        }

        &:hover {
            color: @header-color;
        }
    }

    li {
        display: flex;
        align-items: center;

        &.hidden {
            display: none;
        }
    }

    .delete-hint-suggestion {
        margin-left: auto;
        font-style: normal;
        color: @header-color;
        width: 20px;
        height: 20px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 11px;
        cursor: pointer;

        &:after {
            content: '\e803';
            font-family: icomoon;
        }

        &:hover {
            color: @error-color;
        }
    }
}

.suggestion-result-categories {
    margin-bottom: 22px;
}

.suggestion-result-products {
    flex-grow: 1;
}

.suggestion-product {
    margin-top: 10px;

    &:hover {
        
        b {
            text-decoration: underline;
        }
    }

    a {
        display: block;
        position: relative;
        min-height: 60px;
        padding-left: 80px;
    }

    .size-custom {
        position: absolute;
        top: 0;
        left: 0;
        width: 60px;
        height: 60px;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    img {
        max-width: 100%;
        max-height: 100%;
        align-self: center;
    }

    b {
        color: @header-color;
        display: block;

    }

    span {
        color: @font-color;
    }
}

a.suggestions-advanced-search {
    width: 100%;
    color: @header-color;
    text-decoration: underline;

    &:hover {
        text-decoration: none;
    }
}

// MAIN BAR
@media (min-width: 1280px) {
    .cms-rwd {
        display: none;
    }

    #main-bar {
        position: relative;
        display: flex;
        justify-content: space-between;
        gap: 40px;
        font-family: @sec-font-family;
        font-size: 17px;

        &:after {
            .full-bg();
            background: @sec-color;
            top: 0;
        }
    }

    .mainmenu,
    #tree {
        display: flex;
        align-items: center;
        gap: 40px;

        a {
            color: white;
            white-space: nowrap;
            display: flex;
            gap: 6px;
            align-items: center;
            justify-content: space-between;
        }

        .tree-top > ul > li > a, // controls main bar height
        > li > a {
            padding: 18px 0 20px 0;
        }
    }

    .tree-top,
    .tree-top > ul:not(.cms-rwd) {
        display: contents;
    }

    // TREE
    .tree-top > ul:not(.cms-rwd) {
        position: relative;

        small {
            display: none;
        }

        .parent > a:after { // arrows
            .small-icon();
            content: "\e914";
            font-size: 16px;
        }

        li { // both
            position: relative;

            ul {
                position: absolute;
                background-color: @sec-color;
                opacity: 0;
                visibility: hidden;
                transform: translateY(-10px);
                transition: all .2s ease-in-out;
                min-width: 280px;
                padding: 16px 0 12px 0; //keep the 4px difference
                transition-delay: 0.2s;
            }

            &:hover {

                > ul {
                    opacity: 1;
                    visibility: visible;
                    transform: none !important;
                }
            }
        }

        .t0 { // in main bar
            z-index: 100;
            display: flex;
            align-items: center;

            > ul {
                top: calc(100% - 4px); //redundancy for hover
                left: -24px;
            }

            &:hover > a:after {
                transform: scaleY(-1);
            }
        }

        li:not(.t0) { // nested

            a {
                padding: 12px 24px !important;

                &:after {
                    transform: rotate(-90deg);
                }
            }

            > ul {
                top: -12px;
                left: calc(100% + 1px);
                padding: 12px 0;
                transform: translateX(-10px);

                &:before { // redundancy for hover
                    content: '';
                    height: 100%;
                    width: 15px;
                    background: transparent;
                    display: block;
                    right: calc(100% - 5px);
                    top: 0;
                    position: absolute;
                }
            }
        }
    }
}

// PRODUCTS
@keyframes productImageOpacity {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

.products-list {
    .grid();
    grid-template-columns: repeat(var(--prodCols), minmax(0, 1fr));
    grid-column-gap: var(--prodGap);
    grid-row-gap: var(--prodGapVertical);
    width: 100%;
}

.product {
    position: relative;
    .grid();
    grid-template-rows: auto 1fr;
    gap: 20px;

    &.swiper-slide {
        box-sizing: border-box;
        margin-bottom: 1px;

        .product-name {
            --fontSize: 14px;
            --lineHeight: 1.2;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            display: -webkit-box !important;
            height: ~"calc(2 * (var(--fontSize) * var(--lineHeight)))";
            overflow: hidden;
            text-overflow: ellipsis;
        }
    }

    &:after {
        position: absolute;
        right: calc(-0.5 * var(--prodGap));
        top: 0;
        content: '';
        height: 100%;
        width: 1px;
        background: @border-color;
    }

    &:last-child,
    &:not(.swiper-slide):nth-child(5n) {

        &:after {
            display: none;
        }
    }
}

.product-badges {
    position: absolute;
    top: 8px;
    right: 8px;
    font-size: 12px;
    line-height: 1.2;
    font-weight: 700;
    color: white;
    display: flex;
    justify-content: space-between;
    text-align: center;
    z-index: 1;

    span {
        background-color: @header-color;
        padding: 4px 8px;

        &.promo-badge {
            background-color: @error-color;
        }
    }
}

a.product-name {
    font-weight: 700;
    font-size: 14px;
    line-height: 1.2;
    display: block;
    color: @header-color;
    transition: opacity .1s ease-in-out;
    z-index: 1; // IMPORTANT
    text-align: center;
    color: @header-color;

    &:first-letter {
        text-transform: uppercase;
    }

    &.top-name {
        grid-column: 2 / 3;
        text-align: start;
        align-self: center;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        display: -webkit-box !important;
        overflow: hidden;
        text-overflow: ellipsis;
        --lineHeight: 1.2;
    }

    &.main-name {

        &:after {
            content: '';
            position: absolute;
            inset: 0;
        }
    }
}

.product-wishlist,
.detail-wishlist {
    width: 32px;
    aspect-ratio: 1;
    background: @main-color;
    color: white;
    border-radius: 50px;
    position: absolute;
    bottom: 8px;
    right: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 2;
    transition: background-color 0.1s ease-in;

    &:after {
        .small-icon();
        content: "\e916";
        font-size: 18px;
        transition: color 0.1s ease-in;
    }

    &:hover {
        background: darken(@main-color, 10%);
    }

    &.existing {
        background-color: @sec-color;
        color: @main-color;

        &:hover {
            background: lighten(@sec-color, 10%);
        }
    }
}

.detail-wishlist {
    top: 8px;
    left: 8px;
    bottom: initial;
    right: initial;
}

.product-bottom-wrapper {
    display: flex;
    justify-content: space-between;
    position: relative;
    gap: 2px;
    z-index: 1;

    input[type="radio"]:focus-visible + label,
    input[type="checkbox"]:focus-visible + label,
    *:focus-visible {
        outline-offset: initial;
    }

    .product-counter {
        margin-left: auto;
    }
}

.product-price {
    height: 40px;
    display: flex;
    flex-direction: column;
    justify-content: center;

    &.promo-price b {
    }

    b, del {
        line-height: 1.2;

        &:after {
            content: ' zł';
        }
    }

    b {
        font-size: 16px;
        font-weight: 700;
        color: @header-color;
    }

    del {
        font-size: 14px;
        color: #8C8C8C;
    }
}

.product-add {
    .confirm-button();
    height: 40px;

    &:before {
        content: "\e915";
        .small-icon();
    }

    &.disabled {
        background-color: darken(@border-sec-color, 5%);
        pointer-events: none;
    }
}

.product-image {
    aspect-ratio: 1 / 1;
    position: relative;

    img {
        background: white;
    }

    .top-name,
    p {
        display: none;
        visibility: hidden;
        opacity: 0;
        height: 0 !important;
        --fontSize: 14px;
    }

    p {
        font-size: 14px;
        color: @font-color;
        -webkit-line-clamp: 7;
        -webkit-box-orient: vertical;
        display: block;
        display: -webkit-box !important;
        max-height: ~"calc(7 * (var(--fontSize) * var(--lineHeight))) !important";
        overflow: hidden;
        text-overflow: ellipsis;
    }
}

@media (min-width: 1280px) {
    .has-desc:hover {

        .product-image {
            .grid();
            grid-template-columns: 60px minmax(0, 1fr);
            grid-template-rows: 60px auto;
            gap: 16px 16px;
            position: relative;

            img {
                position: absolute;
                left: 0;
                top: 0;
                width: 60px;
                height: 60px;
                animation: opacityTransition 0.6s;
                transition: width, height;
                transition-delay: .4s;
            }

            .top-name,
            p {
                opacity: 1;
                visibility: visible;
                height: initial !important;
                display: block;
                transition: opacity .2s ease-in-out;
                transition-delay: .4s;
            }

            .top-name {
                max-height: ~"calc(2 * (var(--fontSize) * var(--lineHeight)))" !important;
            }

            p {
                grid-column: 1 / 3;
            }
        }

        .product-badges {
            opacity: 0;
            visibility: hidden;
            transition: 0.2s opacity 0.2s, 0.2s visibility 0.2s;
        }
    }
}

@keyframes opacityTransition {

    33% {
        opacity: 1;
    }

    66% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

// PRODUCT COUNTERS
.product-counter {
    width: 74px;
    position: relative;

    input {
        height: 40px;
        border: 1px solid @main-color;
        width: 100%;
        text-align: center;
        font-size: 16px;
        color: @header-color;
        font-weight: 700;
        line-height: 1.2;
    }

    button {
        position: absolute;
        top: 0;
        width: 20px;
        height: 100%;
        cursor: pointer;
        touch-action: manipulation;

        &:hover {

            &:before,
            &:after {
                background-color: @font-color !important;
            }
        }

        &:before {
            content: '';
            width: 10px;
            height: 2px;
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            background-color: @sec-color;
        }

        &.minus {
            left: 0;

            &:before {
                right: 0px;
            }
        }

        &.plus {
            right: 0;

            &:before {
                left: 0;
            }

            &:after {
                content: '';
                width: 2px;
                height: 10px;
                background-color: @sec-color;
                position: absolute;
                top: 50%;
                transform: translateY(-50%);
                left: 4px;
                border-radius: 50px;
            }
        }
    }
}

// BREADCRUMB
#breadcrumb {
    padding: 24px 0;
    font-size: 14px;

    ul {
        display: flex;
        flex-wrap: wrap;
    }

    li {
        display: flex;

        &:after {
            content: '-';
            margin: 0 5px;
        }

        &:last-child {

            &:after {
                display: none;
            }
        }
    }

    a,
    span {
        color: @font-color;
        display: block;
    }
}

[data-type="mainpage"] #breadcrumb {
    display: none;
}

// LOADER
#loading {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(255,255,255,.5);
    z-index: 99999;
    display: flex;
    justify-content: center;
    align-items: center;
    opacity: 0;
    transition: opacity .2s linear;
    pointer-events: none;

    &.show {
        opacity: 1;
        pointer-events: auto;
    }

    div {
        border-radius: 50%;
        border: 4px solid @border-color;
        border-top-color: @header-color;
        animation: spin .5s infinite linear;
        transform: translateZ(0) rotate(0deg);
        transform-origin: center;
        -webkit-transform-style: preserve-3d;
        width: 100px;
        height: 100px;
    }
}

@media (max-width: 600px) {
    .category-header-wrapper {
        display: contents;
    }

    .category-description {
        order: 2;
    }
}